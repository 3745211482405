export const SPECS = {
  MinimumOrderStorefront: 'specs.stores.MinimumOrderStorefront',
  CartTooltipWithoutNumber: 'specs.stores.CartTooltipWithoutNumber',
  PaypalUpdateShippingAndBilling: 'specs.stores.PaypalUpdateShippingAndBilling',
  FastFlowCustomField: 'specs.stores.FastFlowCustomField',
  SeparatePaymentsStyleCart: 'specs.stores.SeparatePaymentsStyleCart',
  EXPRESS_CASHIER_BI_FIX: 'specs.stores.ExpressCashierBiFix',
  UseCommonProductLineItem: 'specs.stores.UseCommonProductLineItem',
  UseNewSslLogoCart: 'specs.stores.UseNewSslLogoCart',
  CreateCheckoutFromCart: 'specs.stores.CreateCheckoutFromCart',
  CartApiFastFlowWithCreateCheckout: 'specs.stores.CartApiFastFlowWithCreateCheckout',
} as const;
